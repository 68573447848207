import React, {FC} from 'react';
import {useTimeout} from "../../hooks/useTimeout";
import {Cross20Svg} from "../../svg";
import cls from "./toast.module.scss"

interface IProps {
    close: () => any,
    children: any
}

export const Toast: FC<IProps> = (props) => {
    useTimeout(props.close, 2000);


    return (
        <div className={cls.toast}>
            <div className={cls.toast__text}>{props.children}</div>
            <div onClick={props.close} className={cls["toast__close-btn"]}>
                <Cross20Svg/>
            </div>
        </div>
    );
};