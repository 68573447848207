import {createContext, useContext} from 'react'

const createGenericContext = <T>(contextName: string) => {
    // Create a context with a generic parameter or undefined
    const genericContext = createContext<T | undefined>(undefined)

    const useGenericContext = () => {
        const contextIsDefined = useContext(genericContext)
        if (!contextIsDefined) {
            throw new Error(`${contextName} context must be used within a ${contextName} Provider`)
        }
        return contextIsDefined
    }

    return [useGenericContext, genericContext.Provider] as const // for read only purposes
}

export default createGenericContext