import {Provider} from "react-redux";
import store from "./store";
import {FC, ReactNode} from "react";

interface IProps {
    children: ReactNode
}

const AppWrapper: FC<IProps> = ({children}) => {
    return (
        <Provider store={store}>
            {children}
        </Provider>
    );
}

export default AppWrapper;
