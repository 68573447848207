import {ADD_AND_RESET_FORM, SET_FORM_ELEMENT_VALUE, SET_FORM_ERRORS} from "./formBuilderActionTypes";
import {IForm} from "./formBuilderActions";

const initialState: { forms: Array<IForm>, errors: Record<string, Array<string>> } = {
    forms: [],
    errors: {}
}

export default function formBuilderReducer(
    state = initialState,
    action: {
        type: any;
        payload: any;
    }
) {
    switch (action.type) {
        case ADD_AND_RESET_FORM:
            const hasCurrentForm = state.forms.find(el => el.id === action.payload.id);
            return {
                ...state,
                forms: hasCurrentForm? state.forms.map(el => {
                    if(el.id === action.payload.id){
                        return action.payload
                    }
                    return el
                })
                    :
                    [
                        ...state.forms,
                        action.payload
                    ]
            }
        case SET_FORM_ELEMENT_VALUE:
            return {
                ...state,
                forms: state.forms.map(el => {
                    if (el.id === action.payload.id) {
                        return {
                            ...el,
                            formData: el.formData.map((elem, index) => {
                                if (index === action.payload.index) {
                                    return {
                                        ...elem,
                                        ...action.payload.fieldsForChange
                                    }
                                }
                                return elem
                            })
                        }
                    }
                    return el
                })
            }
        case SET_FORM_ERRORS:
            return {
                ...state,
                errors: action.payload
            }
        default:
            return state;
    }
}
