import {combineReducers} from "redux";

import version from "./version";
import cartReducer from "./cart";
import compareReducer from "./compare";
import currencyReducer from "./currency";
import localeReducer from "./locale";
import mobileMenuReducer from "./mobile-menu";
import quickViewReducer from "./quickview";
import sidebarReducer from "./sidebar";
import wishlistReducer from "./wishlist";
import customerReducer from "./customer";
import tokenReducer from "./token";
import generalReducer from "./general";
import rateReducer from "./rate";
import walletReducer from "./walletConnect";
import getProviderReducer from "./getprovider";
import addressReducer from "./addresses";
import smartSearchReducer from "./smart-search";
import checkoutReducer from "./checkout"
import formBuilderReducer from "./formbuilder"

export default combineReducers({
    version: (state = version) => state,
    cart: cartReducer,
    compare: compareReducer,
    currency: currencyReducer,
    locale: localeReducer,
    mobileMenu: mobileMenuReducer,
    quickView: quickViewReducer,
    sidebar: sidebarReducer,
    wishlist: wishlistReducer,
    customer: customerReducer,
    cartToken: tokenReducer,
    general: generalReducer,
    rate: rateReducer,
    wall: walletReducer,
    provider: getProviderReducer,
    addresses: addressReducer,
    smartSearch: smartSearchReducer,
    checkout: checkoutReducer,
    formBuilder: formBuilderReducer
});
