// noinspection JSUnresolvedVariable

const {parseClone} = require("./helpers");
const {colorType} = require("../services/color");
const attributeType = {
    select: "integer_value",
    text: "text_value",
    boolean: "boolean_value",
    textarea: "text_value",
    date: "date_value",
    date_time: "datetime_value"
}

const translatedDataToggle = (newResult, locale) => {
    const seenAttributes = new Set();

    const filteredResult = newResult.filter((item) => {
        const hasPrice = item.attributes.code === "price";
        if (hasPrice && !seenAttributes.has("price")) {
            seenAttributes.add("price");
            return true;
        } else if (!hasPrice) {
            return true;
        }
        return false;
    })
    return filteredResult.map((elem) => {
        return {
            ...elem,
            attributes: [{
                ...elem.attributes[0],
                name: elem?.attributes?.[0]?.translations?.find((ILoc) => locale === ILoc.locale)?.name
            }],
            attribute_options: elem.attribute_options.map((item) => {
                return {
                    ...item,
                    label: item?.translations?.find(ILoc => ILoc.locale === locale)?.label
                }
            })
        }
    });
}

const attributesFetchedData = async (models, catAttributeId, category_id) => {
    let filterAttrWithId = {};
    let filterProductCatWithId = {};
    if (catAttributeId) {
        filterAttrWithId = { id: { "$in": catAttributeId } }
        filterProductCatWithId = category_id !== 1 ? { category_id } : {}
    }
    const attributes = await models.attributes.find({is_filterable: {$in: ["1", 1]}, ...filterAttrWithId})
    const productIds = await models.products_categories.find(filterProductCatWithId)
    const products = await models.products_v2.find({id: productIds.map((elem => elem.product_id))})
    const parsedAttributes = parseClone(attributes);
    const attribute_options = await models.attribute_options.find({attribute_id: {$in: parsedAttributes.map((elem) => elem.id)}});
    const parsedAttrOptions = parseClone(attribute_options);
    const data = await models.product_attribute_values.find({
        attribute_id: {$in: parsedAttributes.map((elem) => elem.id)},
        product_id: {$in: productIds.map((elem => elem.product_id))}
    })
    const productVariants = parseClone(products)
        .filter(element => element.variants.length > 0)
        .map(lll => lll.variants.map(iii => iii.id))
        .flat();
    const variantsData = await models.product_attribute_values.find({
        attribute_id: {$in: parsedAttributes.map((elem) => elem.id)},
        product_id: {$in: productVariants}
    })
    const parsedData = [...parseClone(data), ...parseClone(variantsData)];

    const AttrOptionsIdsSet = new Set();
    parsedData.forEach((item) => {
        if (item.attribute_id !== 11) {
            let attr_value_type = parsedAttributes.find((elem) => elem.id === item.attribute_id).type
            let type = attributeType[attr_value_type]
            AttrOptionsIdsSet.has(item[type])
            AttrOptionsIdsSet.add(item[type])
        }
    })
    const AttrOptionsIds = [...AttrOptionsIdsSet]

    return {
        attributes: parsedAttributes,
        attributesOptions: parsedAttrOptions,
        attributesOptionsIds: AttrOptionsIds
    }
}

const Get_Filters_By_Category = async (category_id, models, locale) => {
    const filterCatId = category_id !== 1? {category_id: category_id} : {}
    if (isNaN(category_id)) {
        return {notFound: true};
    }

    const filterArray = (dataArray) => {
        const uniqueAttributes = {};

        return dataArray.filter((item) => {
            if (!uniqueAttributes[item.attribute_id]) {
                uniqueAttributes[item.attribute_id] = true;
                return true;
            }
            return false;
        });
    };

    try {
        const categoryAttributes = await models.category_filterable_attributes.find(filterCatId);
        const parsedCategoryAttributes = category_id !== 1? parseClone(categoryAttributes) : filterArray(parseClone(categoryAttributes))
        const filterableAttributes = await attributesFetchedData(models, categoryAttributes.map(elem => elem.attribute_id), category_id);
        const newResult = parsedCategoryAttributes.map((elem) => {
            const updatedAttributes = filterableAttributes.attributes.find((ii) => elem.attribute_id === ii?.id);
            const updatedAttrOptions = filterableAttributes.attributesOptions?.filter(
                (el) => el.attribute_id === elem.attribute_id
                    && filterableAttributes.attributesOptionsIds.some((ii) => ii === String(el.id))
            )
            return {
                ...elem,
                attributes: [{
                    ...updatedAttributes,
                    filterType: updatedAttributes?.code !== "color" && updatedAttributes?.code !== "price" ? "list" : updatedAttributes?.code
                }],
                attribute_options: updatedAttrOptions.map(( item ) => {
                    return {
                        ...item,
                        stringifyColorType: colorType(item.swatch_value),
                    }
                })
            }
        })
            .filter(elem => elem.attribute_id !== 11 ? elem.attribute_options.length > 0 : true)
        return translatedDataToggle(newResult, locale);

    } catch (err) {
        throw err;
    }

}

const Get_Filters_Catalog = async (locale, models) => {
    try {
        const filterableAttributes = await attributesFetchedData(models);
        const newResult = filterableAttributes.attributes.map((elem) => {
            const updatedAttrOptions = filterableAttributes.attributesOptions?.filter(
                (el) => el.attribute_id === elem.id
                    && filterableAttributes.attributesOptionsIds.some((ii) => ii === String(el.id))
            ).map(el => {
                el.label = el.translations.find(elem => elem.locale === locale).label
                return el
            })
            const newElem = {...elem}
            newElem.name = newElem.translations.find(el => el.locale === locale)?.name
            return {
                attribute_id: elem.id,
                attributes: [newElem],
                attribute_options: updatedAttrOptions
            }
        })
        return translatedDataToggle(newResult, locale);
    } catch (err) {
        throw err;
    }
}

const Get_All_Attributes = async (options, models) => {
    try {
        const simpleProductsAttributes = await models.product_attribute_values.find({attribute_id: {$in: options.map(elem => elem.attribute_id)}})
        const parsedSimpleProductsAttributes = parseClone(simpleProductsAttributes)
        const configProductAttributes = await models.product_super_attributes.find({attribute_id: {$in: options.map(elem => elem.attribute_id)}})
        const parsedConfigProductAttributes = parseClone(configProductAttributes)
        const productsToggle = await models.products_v2.find({parent_id: {$ne: null}})
        const filteredParentId = parsedSimpleProductsAttributes.filter(simpleAttr => productsToggle.find(product => product.id === simpleAttr.product_id))
        const modifyIdToParentId = filteredParentId.map(simpleAttr => {
            return {
                ...simpleAttr,
                product_id: productsToggle.find(product => product.id === simpleAttr.product_id).parent_id
            }
        });
        const simpleToConfigAttribute = modifyIdToParentId.map(attribute => {
            const hasOptions = options.find((elem) => elem.attribute_id === attribute.attribute_id)
            return {
                attribute_id: attribute.attribute_id === hasOptions.attribute_id ? attribute.attribute_id : "",
                product_id: attribute.product_id,
                [hasOptions.code]: [attribute[attributeType[hasOptions.type]]]
            }
        })
        const unknownConfigAttributes = simpleToConfigAttribute.reduce((acc, curr) => {
            const hasOptions = options.find((elem) => elem.attribute_id === curr.attribute_id)
            const existingProduct = acc.find(item => item.product_id === curr.product_id);

            if (existingProduct) {
                const setAttr = new Set(existingProduct[hasOptions.code]);
                if (!setAttr.has(...curr[hasOptions.code])) {
                    existingProduct[hasOptions.code]?.push(...curr[hasOptions.code]);
                }
            } else {
                acc.push({...curr});
            }

            return acc;
        }, []);

        const allProductsAttributes = [
            ...parsedSimpleProductsAttributes,
            ...parsedConfigProductAttributes,
            ...unknownConfigAttributes
        ]
        const groupedAttributes = allProductsAttributes.reduce((result, item) => {
            const findAttrElem = options.find((elem) => elem.attribute_id === item.attribute_id);
            const product_id = item.product_id;

            if (!result[product_id]) {
                result[product_id] = {product_id};
            }
            result[product_id][findAttrElem.code] = item[attributeType[findAttrElem.type]] ?? item[findAttrElem.code];
            return result;
        }, {});

        return Object.values(groupedAttributes);
    } catch (err) {
        throw err
    }
}

module.exports = {
    Get_All_Attributes,
    Get_Filters_Catalog,
    Get_Filters_By_Category,
};