// noinspection JSUnresolvedVariable

function Get_All_Forms({locale}, models) {
    return new Promise((resolve) => {
        models
            .forms
            .find()
            .then((forms) => {
                resolve({data: forms});
            })
            .catch((err) => reject(err));
    });
}

function Get_Form_By_Id({form_id, locale: locale}, models) {
    return new Promise((resolve, reject) => {
        models
            .forms
            .findOne({id: form_id})
            .then((form) => {
                resolve({data: form});
            })
            .catch((err) => reject(err));
    });
}

module.exports = {
    Get_All_Forms,
    Get_Form_By_Id,
};