import {SIDEBAR_STATE} from './sidebarActionTypes';

const initialState = {
    open: false,
};

export default function sidebarReducer(state = initialState, action) {
    switch (action.type) {
        case SIDEBAR_STATE:
            return {
                ...state,
                open: !state.open,
            };
        default:
            return state;
    }
}
