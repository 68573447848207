const SettingsController = require("../controllers/SettingsController.js");
const CategoryControllers = require("../controllers/CategoryController.js");
const PageControllers = require("../controllers/PageControllers.js");

interface IOption {
    dbName: string;
    domain?: string
    locale: any
    selectedCurrency: string;
}

const generalAction = {

    async action(action: string, option: IOption, models: any) {
        const {locale, dbName, selectedCurrency, domain} = option;

        switch (action) {
            case "General":
                return await this.general(locale, dbName, selectedCurrency, domain!, models);
            case "CustomSettings":
                return await this.customSettings(locale, dbName, selectedCurrency, domain!, models);
            case "Menus":
                return await this.ApiMenus(locale, models);
            case "Categories":
                return await this.ApiCategories(locale, models);
            default:
        }
    },

    async general(locale: string, dbName: string, selectedCurrency: string, domain: string, models: any): Promise<any> {
        try {
            const customSettingsResult = await this.action("CustomSettings", {
                locale,
                dbName,
                selectedCurrency,
                domain
            }, models);

            const newLocal = locale !== "catchAll" ? locale : customSettingsResult?.data.locale.code;

            const [menus, categories] = await Promise.all([
                this.action("Menus", {locale: newLocal, dbName, selectedCurrency}, models),
                this.action("Categories", {locale: newLocal, dbName, selectedCurrency}, models),
            ]);

            const dispatches = {
                serverSide: {
                    ...customSettingsResult.dispatches.serverSide,
                    ...categories,
                    ...menus,
                },
                clientSide: customSettingsResult.dispatches.clientSide,
            };

            const currency = customSettingsResult.data?.currency?.code || customSettingsResult.data.rate.current.code;

            return {
                locale: newLocal,
                rate: customSettingsResult.data.rate,
                currency,
                dispatches,
                categories: categories.setCategories,
                menus: menus.setMenuList,
                coreConfigs: customSettingsResult.data.coreConfigs,
                locales: customSettingsResult.data.locales,
                translations: customSettingsResult.data.translations,
            };
        } catch (err) {
            throw err;
        }
    },


    async ApiCategories(locale: string, models: any): Promise<any> {
        try {
            console.time("Get_Categories Request >>>>")
            const category = await CategoryControllers.Get_Categories(locale, models);
            console.timeEnd("Get_Categories Request >>>>")
            return {setCategories: category};
        } catch (err) {
            throw err;
        }
    },

    async ApiMenus(locale: any, models: any): Promise<any> {
        try {
            const menus = await CategoryControllers.Get_Menus(locale, models)
            const headerMenus = menus.filter((item: { type: string }) => item.type === "header")
                .map(({url_key, slug, new_tab, ...menu}: { url_key: string, slug: string, new_tab: boolean, name: string }) => {
                    if (url_key) {
                        return {
                            ...menu,
                            target: new_tab ? {target: "_blank"} : {},
                            url_key,
                            slugCheckout: slug
                        }
                    }
                    return {
                        ...menu,
                        target: new_tab ? {target: "_blank"} : {},
                        url_key: `/page/${slug}`,
                        slugCheckout: slug
                    }
                })
                .sort((a: { position: number }, b: { position: number }) => a.position - b.position)
            const footerMenus = menus.filter((item: { type: string }) => item.type === "footer")
                .map(({url_key, slug, new_tab, ...menu}: { url_key: string, slug: string, new_tab: boolean }) => {
                    if (url_key) {
                        return {
                            ...menu,
                            target: new_tab ? {target: "_blank"} : {},
                            url_key,
                            slugCheckout: slug
                        }
                    }
                    return {
                        ...menu,
                        target: new_tab ? {target: "_blank"} : {},
                        url_key: `/page/${slug}`,
                        slugCheckout: slug
                    }
                })
                .sort((a: { position: number }, b: { position: number }) => a.position - b.position)
            return ({ setMenuList: headerMenus || false, setFooterMenuList: footerMenus });
        } catch (err) {
            throw err;
        }
    },

    async customSettings(locale: string, dbName: string, selectedCurrency: string, domain: string, models: any): Promise<any> {
        try {
            console.time("Get_Settings Request >>>>")
            const response: any = await SettingsController.Get_Settings(locale, models);
            console.timeEnd("Get_Settings Request >>>>")
            const {channel_info} = await response;
            const {
                locales,
                currencies,
                currencies_new,
                base_currency_id,
                default_locale_id,
            } = JSON.parse(JSON.stringify(channel_info[0]));

            const selectedLocale = locales?.find((item: any): any => {
                if (locale != "catchAll") {
                    return item.code === locale;
                } else {
                    return item.id === default_locale_id;
                }
            })

            console.time("Get_Core_Config Request >>>>")
            const res: any = await SettingsController.Get_Core_Config(selectedLocale.code, models);
            console.timeEnd("Get_Core_Config Request >>>>")
            let data: any = {};

            let dispatches = {

                serverSide: {
                    setDomain: domain || false,
                    setSocial: response?.social || false,
                    setFbPixel: response?.store_info?.facebook_pixel_id || false,
                    setTranslations: false
                },

                clientSide: {
                    setPopup: response?.popup || false,
                    setCoreConfigs: res || false,
                    setCustomJs: response?.store_info?.custom_js || false,
                    setBackorders: Boolean(+res?.catalog_inventory_stock_options_backorders?.value) || false,
                    setCacheVersion: res?.general_cash_control_version?.value || false,
                    setLocaleList: false,
                    changeLocale: false,
                    setCurrencies: false,
                    setRate: {} || false,
                },
            };

            if (channel_info?.length > 0) {

                dispatches["clientSide"]["setLocaleList"] = locales || false;
                dispatches["clientSide"]["changeLocale"] = locale != "catchAll" ? locale : default_locale_id;
                dispatches["clientSide"]["setCurrencies"] = currencies || false;
                dispatches["clientSide"]["setRate"] =
                    {
                        list: currencies_new,
                        current: selectedCurrency !== "" && selectedCurrency !== "[object Object]"
                            ? currencies_new?.find(
                            (item: any) => {
                                return item.code == selectedCurrency
                            }
                        ) ?? null
                            : currencies_new?.find(
                            (item: any) => {
                                return item.id === base_currency_id
                            }
                        ) ?? null
                    } || false;

                data = {
                    locales,
                    locale: selectedLocale,
                    rate: {
                        currencies_new,
                        current: currencies_new?.find(
                            (item: any) => item.id === base_currency_id
                        ),
                        BaseCurrId: base_currency_id,
                    },
                    currency: currencies?.find(
                        (currency: any) => {
                            if (selectedCurrency !== "" && selectedCurrency !== "[object Object]") {
                                return currency.code == selectedCurrency
                            } else return currency.id === base_currency_id
                        }
                    ) ?? null,
                    coreConfigs: res,
                };
            }

            if (channel_info?.length && data.locale?.code && dbName) {
                console.time("Get_Translations Request >>>>")
                const apt: any = await SettingsController.Get_Translations(data.locale.code, models);
                console.timeEnd("Get_Translations Request >>>>")
                dispatches["serverSide"]["setTranslations"] = apt || false;
                data.translations = apt[data.locale.code].translations
                return {
                    data,
                    dispatches,
                };
            }
        } catch (error) {
            throw error;
        }
    }

};
export default generalAction;
