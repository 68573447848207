import {
    SET_BILLING_STATE,
    SET_SHIPPING_COUNTRY,
    SET_BILLING_COUNTRY,
    SET_SHIPPING_STATE,
    RESET_CHECKOUT_COUNTRIES,
    SET_SHIP_COUNTRY_HAS_STATES,
    SET_BIL_COUNTRY_HAS_STATES,
    SET_ALL_SHIPPING_RATES,
    SET_SIGNED_USER_ADDRESS_ID,
    SET_SHOW_SHIPPING_ADDRESS,
    SET_SHIPPING_RATE,
    SET_SHIPPING_METHOD,
    SET_SHIPPING_ZERO,
} from "./checkoutActionTypes"

const initialState = {
    shipping: {
        country: "",
        state: "",
        has_state: false,
    },
    billing: {
        country: "",
        state: "",
        has_state: false,
    },
    rates: [],
    signed_user_id: "",
    show_shipping: false,
    shipping_rate: "",
    shipping_method: "",
    shipping_zero: false,
};

export default function generalReducer(state = initialState, action: { type: any; payload: any; }) {
    switch (action.type) {
        case SET_BILLING_STATE:
            return {
                ...state,
                billing: {
                    ...state.billing,
                    state: action.payload
                },
            };

        case SET_SHIPPING_COUNTRY:
            return {
                ...state,
                shipping: {
                    ...state.shipping,
                    country: action.payload
                },
            };

        case SET_SHIPPING_STATE:
            return {
                ...state,
                shipping: {
                    ...state.shipping,
                    state: action.payload
                },
            };

        case SET_BILLING_COUNTRY:
            return {
                ...state,
                billing: {
                    ...state.billing,
                    country: action.payload
                },
            };

        case SET_SHIP_COUNTRY_HAS_STATES:
            return {
                ...state,
                shipping: {
                    ...state.shipping,
                    has_state: action.payload
                },
            };

        case SET_BIL_COUNTRY_HAS_STATES:
            return {
                ...state,
                billing: {
                    ...state.billing,
                    has_state: action.payload
                },
            };

        case SET_ALL_SHIPPING_RATES:
            return {
                ...state,
                rates: action.payload,
            };

        case SET_SIGNED_USER_ADDRESS_ID:
            return {
                ...state,
                signed_user_id: action.payload,
            }

        case SET_SHOW_SHIPPING_ADDRESS:
            return {
                ...state,
                show_shipping: action.payload,
            }

        case SET_SHIPPING_RATE:
            return {
                ...state,
                shipping_rate: action.payload,
            }

        case SET_SHIPPING_METHOD:
            return {
                ...state,
                shipping_method: action.payload,
            }

        case SET_SHIPPING_ZERO:
            return {
                ...state,
                shipping_zero: action.payload,
            }

        case RESET_CHECKOUT_COUNTRIES:
            return {
                ...initialState
            }

        default:
            return state;
    }
}