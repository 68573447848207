import {FC, ReactNode, useEffect, useState} from 'react'
import AgeVerificationPopup from "../components/popupComponents/AgeVerify/AgeVerificationPopup";
import {useRouter} from "next/router";
import cls from "../components/popupComponents/AgeVerify/ageVerify.module.scss";

type ageVerifyType = {
    ageVerifyTitle: string,
    ageVerifyYes: string,
    ageVerifyNo: string,
    ageVerifyActive: boolean,
    ageVerifyCatPaths: Array<string>,
    ageVerifyImagePath: string
}

interface IGlobalProps {
    children: ReactNode,
    ageVerifications: ageVerifyType
}

export const AgeVerificationPopupLayout: FC<IGlobalProps> = ({children, ageVerifications = {}}) => {
    const {
        ageVerifyActive = false,
        ageVerifyCatPaths = [],
    } = ageVerifications

    const [popupIsOpen, setPopupIsOpen] = useState<boolean>(false)
    const [isBlurred, setIsBlurred] = useState<boolean>(false)
    const router = useRouter()

    useEffect(() => {
        const ageVerify = localStorage.getItem("ageVerify")
        setPopupIsOpen(ageVerify !== "true")
        setIsBlurred(ageVerify !== "true")
    }, [router.asPath])

    const handleClickYes = () => {
        localStorage.setItem("ageVerify", "true")
        setPopupIsOpen(false)
        setIsBlurred(false)
    }

    const handleClickNo = () => {
        if (ageVerifyCatPaths.length) {
            router.push("/")
        } else {
            setPopupIsOpen(false)
        }
        localStorage.setItem("ageVerify", "false")
    }

    return ageVerifyActive &&
    (!ageVerifyCatPaths.length || ageVerifyCatPaths.some(path => path === router.asPath)) ?
        <div>
            {isBlurred && <div className={cls.popup_wrapper} style={{backdropFilter: "blur(30px)"}}>
                {popupIsOpen && <AgeVerificationPopup
                    handleClickNo={handleClickNo}
                    handleClickYes={handleClickYes}
                    ageVerifications={ageVerifications as ageVerifyType}
                />}
            </div>}
            {children}
        </div>
        :
        <>{children}</>
}

