const {parseClone, handleContentIsEmpty} = require("./helpers");

function Get_Page_By_Slug({pageSlug, locale}, models) {
    return new Promise(async (resolve, reject) => {
        const pages = new Promise((resolve1, reject1) => {
            models.pages.aggregate([
                {
                    $match: {url_key: pageSlug}
                },
            ]).exec((err, result) => {
                if (err) {
                    reject1(err);
                } else {
                    if (result.length > 0) {
                        const translatedData = result.map(el => {
                            const translation = el.translations.find(elem => elem.locale === locale)
                            const tableRegex = /<table[\s\S]*?>/
                            const containsTable = tableRegex.test(translation?.html_content)
                            const hasContent = handleContentIsEmpty(translation?.html_content)

                            if (translation?.html_content.includes("SmartSearch")) {

                                let start = translation?.html_content.indexOf("&lt;")
                                let end = translation?.html_content.indexOf("/&gt;")
                                let result = translation?.html_content.slice(start - 2, end)
                                let shortCode = result.replace(/\&nbsp;/g, '');
                                let shortCodeActions = shortCode.split(" ")


                                let shortCodeId = shortCodeActions[1]?.slice(shortCodeActions[1]?.indexOf("=") + 1)
                                let step1_title = shortCodeActions[2]?.slice(shortCodeActions[2]?.indexOf("=") + 1)
                                let step2_title = shortCodeActions[3]?.slice(shortCodeActions[3]?.indexOf("=") + 1)
                                let step3_title = shortCodeActions[4]?.slice(shortCodeActions[4]?.indexOf("=") + 1)
                                let new_search = shortCodeActions[5]?.slice(shortCodeActions[5]?.indexOf("=") + 1)
                                return {
                                    ...el,
                                    ...translation,
                                    cms_page_id: el.id,
                                    image: el?.image || "configuration/logo/logo.webp",
                                    html_content: translation.html_content.replace(result, ""),
                                    hasShortCode: true,
                                    shortCodeId: shortCodeId,
                                    step1_title,
                                    step2_title,
                                    step3_title,
                                    new_search,
                                    isTable: containsTable,
                                    contentHeight: hasContent ? {minHeight: "unset"} : {}
                                }
                            }

                            return {
                                ...el,
                                ...translation,
                                hasShortCode: false,
                                cms_page_id: el.id,
                                image: el?.image || "configuration/logo/logo.webp",
                                isTable: containsTable,
                                contentHeight: hasContent ? {} : {minHeight: "unset"}
                            }
                        })
                        resolve1(translatedData);
                    } else {
                        resolve({notFound: true});
                    }
                }
            });
        });
        const pageYoast = new Promise(async (resolve2, reject2) => {
            try {
                const findCurrYoast = await pages;

                models.yoast_pages.aggregate([
                    {
                        $match: {
                            page_id: findCurrYoast[0].cms_page_id,
                            locale: locale
                        }
                    }
                ]).exec((err, item) => {
                    if (err) {
                        reject2(err);
                    } else {
                        resolve2(item);
                    }
                });
            } catch (error) {
                reject2(error);
            }
        });
        const parsedPages = (await pages).map(el => {
            return {
                ...el,
                meta_title: el.meta_title !== "" ? el.meta_title : el.page_title,
            }
        });
        const parsedYoast = (await pageYoast).map(el => {
            return {
                ...el,
                yoast_facebook_title: el.yoast_facebook_title !== "" ? el.yoast_facebook_title : parsedPages.meta_title,
                yoast_twitter_title: el.yoast_twitter_title !== "" ? el.yoast_twitter_title : parsedPages.meta_title,
                yoast_facebook_description: el.yoast_facebook_description !== "" ? el.yoast_facebook_description : parsedPages.meta_description,
                yoast_twitter_description: el.yoast_twitter_description !== "" ? el.yoast_twitter_description : parsedPages.meta_description,
                yoast_focus_keyword: el.yoast_focus_keyword !== "" ? el.yoast_focus_keyword : parsedPages.meta_keywords,
            }
        });
        resolve({data: parseClone(parsedPages), yoast: parseClone(parsedYoast) || null})
    });
}

const Get_Maintenance_Channel = async (locale, models, ipv4) => {
    try {
        const pipeline = [
            {$match: {translations: {$exists: true}}},
            {
                $project: {
                    is_maintenance_on: "$is_maintenance_on",
                    maintenance_mode_text: "$maintenance_mode_text",
                    maintenance_mode_end_date: "$maintenance_mode_end_date",
                    allowed_ips: "$allowed_ips",
                    translations: {
                        $filter: {
                            input: '$translations',
                            as: 'translation',
                            cond: {$eq: ['$$translation.locale', locale]}
                        }
                    },
                }
            }
        ];
        const channels = await models.channels.aggregate(pipeline);
        const {
            translations: [
                {
                    maintenance_mode_text: maintenanceIntl = null
                } = {}
            ] = [],
            allowed_ips,
            is_maintenance_on,
            ...otherChannels
        } = channels[0] || {};
        const redirectMaintenanceHandle = () => {
            if (Number(is_maintenance_on)) {
                const ipsAllow = allowed_ips.split(',').some(id => id === ipv4);
                if (ipsAllow) return 0
                return 1
            }
            return 0
        }
        return JSON.parse(JSON.stringify({
            ...otherChannels,
            is_maintenance_on: redirectMaintenanceHandle(),
            maintenance_mode_text: maintenanceIntl
                ? maintenanceIntl
                : otherChannels?.maintenance_mode_text || ""
        }))
    } catch (err) {
        throw err
    }
}


/********************** new code ****************************/
const Get_Channels = async (locale, models) => {
    try {
        const pipeline = [
            {$match: {translations: {$exists: true}}},
            {
                $project: {
                    share_pic: "$share_pic",
                    translations: {
                        $filter: {
                            input: '$translations',
                            as: 'translation',
                            cond: {$eq: ['$$translation.locale', locale]}
                        }
                    },
                }
            }
        ];
        const channels = await models.channels.aggregate(pipeline);
        if (channels[0].translations?.[0]?.home_seo) {
            return {
                ...JSON.parse(channels[0].translations?.[0]?.home_seo),
                share_pic: channels[0]?.share_pic
            }
        }
        return {}
    } catch (err) {
        throw err;
    }
}

function Get_Pages(locale, models) {
    return new Promise((resolve, reject) => {
        models
            .pages
            .find()
            .then((res) => {
                const filteredPages = res.map((translationArr) => {
                    return {
                        cms_page_id: translationArr.id,
                        ...translationArr.translations.find((item) => item.locale === locale)
                    }
                });
                resolve(parseClone(filteredPages))
            })
            .catch((err) => reject(err));
    })
}

module.exports = {
    Get_Channels,
    Get_Page_By_Slug,
    Get_Pages,
    Get_Maintenance_Channel
};