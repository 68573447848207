function filterBy(element, filters, type, products) {
    const rangeFilters = ['price'];
    const priceHandle = (min, max, { min_price }) => {
        let element_value = Number(parseFloat(min_price).toFixed(2))
        return element_value === min || element_value === max || (min < element_value && element_value < max);
    }
    return Object.entries(filters).every(([filter_key, filter_value]) => {
        const finalFilterValue = filter_value.includes(',') ? filter_value.split(',') : filter_value
        const multiValue = Array.isArray(finalFilterValue) && finalFilterValue.length > 1;
        if (rangeFilters.includes(filter_key)) {
            const [min, max] = finalFilterValue.map(Number)
            if (type) {
                return products?.some(el => priceHandle(min, max, el.flats[0]))
            }
            return priceHandle(min, max, element)
        } else if (multiValue && !rangeFilters.includes(filter_key)) {
            if (Array.isArray(element[filter_key])) {
                return finalFilterValue.some(multiFilterElem => element[filter_key].some(lll => String(lll) === String(multiFilterElem)))
            }
            return finalFilterValue.some(multiFilterElem => String(element[filter_key]) === String(multiFilterElem))
        } else {
            if (Array.isArray(element[filter_key])) {
                return element[filter_key].some(lll => String(lll) === String(filter_value))
            }
            return String(element[filter_key]) === String(filter_value)
        }
    })
}

module.exports = {
    filterBy,
}