export const SET_SHIPPING_COUNTRY = "SET_SHIPPING_COUNTRY"
export const SET_BILLING_COUNTRY = "SET_BILLING_COUNTRY"
export const SET_SHIPPING_STATE = "SET_SHIPPING_STATE"
export const SET_BILLING_STATE = "SET_BILLING_STATE"
export const RESET_CHECKOUT_COUNTRIES = "RESET_CHECKOUT_COUNTRIES"
export const SET_SHIP_COUNTRY_HAS_STATES = "SET_SHIP_COUNTRY_HAS_STATES"
export const SET_BIL_COUNTRY_HAS_STATES = "SET_BIL_COUNTRY_HAS_STATES"
export const SET_ALL_SHIPPING_RATES = "SET_ALL_SHIPPING_RATES"
export const SET_SIGNED_USER_ADDRESS_ID = "SET_SIGNED_USER_ADDRESS_ID"
export const SET_SHOW_SHIPPING_ADDRESS = "SET_SHOW_SHIPPING_ADDRESS"
export const SET_SHIPPING_RATE = "SET_SHIPPING_RATE"
export const SET_SHIPPING_METHOD = "SET_SHIPPING_METHOD"
export const SET_SHIPPING_ZERO = "SET_SHIPPING_ZERO"
