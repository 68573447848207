const httpPort = process.env.NODE_ENV === "development" ? "http" : "https"

exports.url = process.env.domainName
    ? `${httpPort}://${process.env.domainName}`
    : `/`;

exports.megaUrl = process.env.domainName
    ? `${httpPort}://${process.env.domainName}`
    : ``;

exports.urlWithoutLocal = process.env.domainName ? `/` : ``;

exports.imgUrlPath = process.env.NODE_ENV === "development" ? "/" : "/"

exports.urlLink = process.env.domainName
    ? `${httpPort}://${process.env.domainName}`
    : `${httpPort}://pic.zegashop.com`;

const phpPath = process.env.domainName
    ? `${httpPort}://${process.env.domainName}`
    : `${httpPort}://pic.zegashop.com`;

exports.apiImageUrl = ``;

exports.apiUrl = phpPath;

exports.storeName = process.env.domainName
    ? process.env.domainName.split(".")[0]
    : "";

exports.apiUrlWithStore = string => `${string}`;

exports.domainUrl = string => `${httpPort}://${string}`;