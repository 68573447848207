import React, {FC} from 'react'
import cls from "./ageVerify.module.scss"

interface IProps {
    handleClickYes: () => void,
    handleClickNo: () => void,
    ageVerifications: {
        ageVerifyTitle: string,
        ageVerifyYes: string,
        ageVerifyNo: string,
        ageVerifyActive: boolean,
        ageVerifyImagePath: string,
        ageVerifyCatPaths: Array<string>,
    }
}

const AgeVerificationPopup: FC<IProps> = (
    {
        handleClickNo,
        handleClickYes,
        ageVerifications: {
            ageVerifyTitle = "",
            ageVerifyYes = "",
            ageVerifyNo = "",
            ageVerifyImagePath = "",
        }
    }
) => {
    return <div className={cls.popup}>
        <img
            src={ageVerifyImagePath}
            alt=""
            loading="lazy"
            width="136"
            height="136"
        />
        <div className={cls.popup_title}>
            {ageVerifyTitle}
        </div>
        <div className={cls.popup_buttons}>
            <button className={cls.decline} onClick={handleClickNo}>{ageVerifyNo}</button>
            <button className={cls.accept} onClick={handleClickYes}>{ageVerifyYes}</button>
        </div>
    </div>
}


export default AgeVerificationPopup
