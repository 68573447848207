import {FC, ReactNode} from 'react'
import {IProps} from "../types/homePageTypes";
import {GlobalContextProvider} from "../context/GlobalContext";

interface IGlobalProps {
    children: ReactNode
    value: IProps
}

export const GlobalProvider: FC<IGlobalProps> = ({value, children}) => {


    return <GlobalContextProvider value={value}>{children}</GlobalContextProvider>
}