const domainUrl = string => `${process.env.NODE_ENV === "development" ? "http" : "https"}://${string}`;

const buildData = (res, locale) => {

    let translatedData = []
    res.map((blog) => {
        let translated = blog?.translations?.find((translate) => {
            if (translate.locale === locale) {
                const localed = JSON.parse(JSON.stringify({
                    ...translate,
                    image: blog.image,
                    created_at: blog.created_at,
                    published_at: blog.published_at
                }));
                translatedData?.push(localed);
            }
        });
        translatedData?.push(translated);
    });
    return translatedData?.filter(el => el);
}

async function Get_All_Posts(
    {
        domain,
        dbName,
        locale,
        page = 1,
        limit = 6,
        filterTags = [],
        filterCategory = "all"
    }, models
) {
    let postIds = []
    let catId = []

    try {
        const postTags = await models.tags_posts.find({blog_tag_id: {$in: filterTags}});
        postIds = [...new Set(postTags.map((e) => e.blog_post_id))]
        const postCategories = await models.post_categories.find({slug: {$in: filterCategory}})
        const pivotCategories = await models.post_categories_posts.find({blog_category_id: {$in: postCategories[0]?.id}})
        catId = [...new Set(pivotCategories.map((e) => e.blog_post_id))]

    } catch (err) {
        throw err;
    }
    return new Promise((resolve) => {
        models
            .posts
            .countDocuments({})
            .exec((count_error, count) => {
                if (count_error) {
                    let res;
                    return res.json(count_error);
                }
                const pageCount = Math.ceil(count / limit);
                const skip = (+page - 1) * limit;
                models
                    .posts
                    .aggregate([
                        {
                            $match: postIds.length > 0 ? {id: {$in: postIds}} : {}
                        },
                        {
                            $match: catId.length === 0 && filterCategory === "all" ? {} : {id: {$in: catId}}
                        },
                        {
                            $match: {status: true}
                        },
                        {
                            $lookup: {
                                from: "post_categories_posts",
                                localField: "id",
                                foreignField: "blog_post_id",
                                as: "post_categories",
                            }
                        },
                        {
                            $unwind: "$post_categories" // Unwind the array of categories
                        },
                        {
                            $match: {"post_categories.is_default": 1} // Filter for the category with is_default: 1
                        },
                        {
                            $lookup: {
                                from: "post_categories",
                                localField: "post_categories.blog_category_id",
                                foreignField: "id",
                                as: "default_category"
                            }
                        },
                        {
                            $unwind: "$default_category" // Unwind the array of categories
                        },
                        {
                            $group: {
                                _id: "$_id",
                                author_id: {$first: "$author_id"},
                                published_at: {$first: "$published_at"},
                                status: {$first: "$status"},
                                allow_comments: {$first: "$allow_comments"},
                                updated_at: {$first: "$updated_at"},
                                created_at: {$first: "$created_at"},
                                name: {$first: "$name"},
                                slug: {$first: "$slug"},
                                short_description: {$first: "$short_description"},
                                description: {$first: "$description"},
                                meta_title: {$first: "$meta_title"},
                                meta_description: {$first: "$meta_description"},
                                meta_keywords: {$first: "$meta_keywords"},
                                translations: {$first: "$translations"},
                                image: {$first: "$image"},
                                default_category: {$first: "$default_category"}
                            }
                        },
                        {
                            $sort: {_id: -1} // Sort by _id in descending order
                        }, {
                            $sort: {updated_at: -1} // Sort by _id in descending order
                        },
                        {
                            $skip: skip // Skip documents
                        },
                        {
                            $limit: +limit // Limit documents
                        }
                    ], function (err, results) {
                        if (err) {
                            // Handle error
                            console.error(err);
                        } else {
                            const translatedData = results.map(result => {
                                return {
                                    ...result,
                                    ...result.translations.find(el => el.locale === locale),
                                    image: result.image
                                        ? domainUrl(`${dbName}/storage/${domain}/${result.image}`)
                                        : domainUrl(`${dbName}/admin-themes/wt-admin-theme-one/assets/images/placeholder-icon.svg`),
                                    default_category: {
                                        ...result.default_category,
                                        ...result.default_category.translations.find(el => el.locale === locale),
                                        slug: result.default_category.slug
                                    },
                                }
                            })

                            resolve({
                                // data: JSON.parse(JSON.stringify(results)),
                                data: JSON.parse(JSON.stringify(translatedData)),
                                meta: {
                                    current_page: page,
                                    total: pageCount,
                                },
                                links: {},
                            })
                        }
                    })
            })
    });
}


function Get_Post_By_Slug({locale: locale, slug}, models) {
    return new Promise((resolve, reject) => {
        models
            .posts
            .findOne({slug})
            .then((post) => {
                if (!post) {
                    resolve({notFound: true})
                }
                const translatedData = {
                    ...JSON.parse(JSON.stringify(post)),
                    ...post.translations.find(el => el.locale === locale),
                    slug: post.slug,
                }

                const stripTags = (html) => {
                    return html.replace(/<[^>]*>/g, '');
                }

                const dataWithMetas = {
                    ...translatedData,
                    meta_title: translatedData.meta_title? translatedData.meta_title : translatedData.name,
                    meta_description: translatedData.meta_description? translatedData.meta_description : stripTags(translatedData.description),
                    meta_image: translatedData.image? translatedData.image : "configuration/logo/logo.webp"
                }
                resolve(dataWithMetas);
            })
            .catch((err) => reject(err));
    });
}

function Get_Posts_Categories({locale}, models) {
    return new Promise((resolve, reject) => {
        models
            .post_categories
            .find({status: true})
            .then((postCategories) => {
                const translatedData = postCategories.map(cat => {
                    return {
                        ...JSON.parse(JSON.stringify(cat)),
                        ...cat.translations.find(el => el.locale === locale),
                        slug: cat.slug
                    }
                })

                resolve(translatedData);
            })
            .catch(err => reject(err))

    })
}

function Get_Post_Cat_By_Slug({locale: locale, slug}, models) {
    return new Promise((resolve, reject) => {
        models
            .post_categories
            .findOne({slug})
            .then((post_cat) => {
                if (!post_cat) {
                    resolve({ notFound: true })
                }
                const translatedData = {
                    ...JSON.parse(JSON.stringify(post_cat)),
                    ...post_cat.translations.find(el => el.locale === locale),
                    slug: post_cat.slug
                }

                const resultWithMeta = {
                    ...translatedData,
                    meta_title: translatedData.meta_title? translatedData.meta_title : translatedData.name,
                    meta_description: translatedData.meta_description? translatedData.meta_description : translatedData.description,
                    meta_image: translatedData.image? translatedData.image : "configuration/logo/logo.webp",
                }

                resolve(resultWithMeta);
            })
            .catch((err) => reject(err));
    });
}

function Get_Posts_By_Category_Slug({slug, locale}, models) {


    if (slug === "undefined") {

        return Get_All_Posts({locale}, models)
    } else {
        return new Promise((resolve, reject) => {
            models
                .post_categories
                .findOne({slug})
                .then((blog) => {

                    let catID = blog.id


                    return new Promise((resolve2, reject2) => {
                        models
                            .post_categories_posts
                            .aggregate(
                                [
                                    {
                                        $lookup: {
                                            from: "posts",
                                            localField: "blog_post_id",
                                            foreignField: "id",
                                            as: "post",

                                        },
                                    },
                                    {
                                        $match: {
                                            blog_category_id: catID
                                        }
                                    }

                                ]).then(res => {


                            let posts = res.map(postData => postData.post).flat()

                            resolve({data: buildData(posts, locale)});
                        }).catch(err => reject2(err))
                    })
                    // resolve({data: newTranslatedData});
                }).catch(err => reject(err))

        })
    }

}

function Get_All_Tags({locale}, models) {
    return new Promise((resolve, reject) => {

        models
            .tags
            .find({status: true})
            .then((tags) => {
                const test = [...tags.map(tag => {
                    return {
                        ...JSON.parse(JSON.stringify(tag)),
                        ...tag.translations?.find(el => el.locale === locale)
                    }
                })]
                resolve(test);
            })
            .catch(err => reject(err))
    })
}

async function Get_Tags_By_Slug({locale, slug}, models) {

    let tagIds = []

    try {
        const post = await models.posts.findOne({slug})
        const tagsByPost = await models.tags_posts.find({blog_post_id: {$in: post?.id}})
        tagIds = tagsByPost.map(el => el.blog_tag_id)

    } catch (err) {
        throw err;
    }
    return new Promise((resolve, reject) => {
        models
            .tags
            .find({
                status: true,
                id: tagIds
            })
            .then((tags) => {
                const test = [...tags.map(tag => {
                    return {
                        ...JSON.parse(JSON.stringify(tag)),
                        ...tag.translations?.find(el => el.locale === locale)
                    }
                })]
                resolve(test);
            })
            .catch(err => reject(err))
    })
}


module.exports = {
    Get_All_Posts,
    Get_Post_By_Slug,
    Get_Posts_Categories,
    Get_Post_Cat_By_Slug,
    Get_All_Tags,
    Get_Tags_By_Slug
};