const Get_Needed_Attribute = async (models, code, name) => {
    try {
        const result = await models.attributes.find({code})
        return {
            [name]: result
        };
    } catch (error) {
        throw error;
    }
}

module.exports = {
    Get_Needed_Attribute,
}